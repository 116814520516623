document.addEventListener('DOMContentLoaded', function() {
    const reportButtons = document.querySelectorAll('.report-btn');
    const reportForm = document.getElementById('reportForm');
    const reportContentId = document.getElementById('reportContentId');
    const reportContentType = document.getElementById('reportContentType');

    reportButtons.forEach(button => {
        button.addEventListener('click', function() {
            reportContentId.value = this.dataset.contentId;
            reportContentType.value = this.dataset.contentType;
        });
    });

    reportForm.addEventListener('submit', function(e) {
        e.preventDefault();

        const formData = new FormData(this);

        fetch('/report', {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    showToast(data.message, 'success');
                    // Close the modal
                    document.querySelector('[data-modal-hide="reportModal"]').click();
                    // Reset the form
                    reportForm.reset();
                } else {
                    showToast(data.message, 'error');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                showToast('There was an error submitting your report. Please try again.', 'error');
            });
    });
});
