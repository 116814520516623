import {addVoteEventListeners} from "@/vote.js";
import { initFlowbite } from 'flowbite';

export function initializeBookmarkButtons() {
    document.querySelectorAll('.bookmark-button:not(.initialized)').forEach(button => {
        button.addEventListener('click', bookmarkClickHandler);
        button.classList.add('initialized');
    });
}

function bookmarkClickHandler(event) {
    event.preventDefault();
    const button = this;
    const memeId = button.dataset.memeId;

    if (button.dataset.processing === 'true') return;
    button.dataset.processing = 'true';

    fetch(`/bookmarks/${memeId}`, {
        method: 'POST',
        headers: {
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ memeId })
    })
        .then(response => response.json())
        .then(data => {
            button.dataset.bookmarked = data.bookmarked;
            updateBookmarkButtonAppearance(button, data.bookmarked);
        })
        .catch(error => console.error('Error:', error))
        .finally(() => {
            button.dataset.processing = 'false';
        });
}

function updateBookmarkButtonAppearance(button, isBookmarked) {
    const svgFilled = `<svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M7.833 2c-.507 0-.98.216-1.318.576A1.92 1.92 0 0 0 6 3.89V21a1 1 0 0 0 1.625.78L12 18.28l4.375 3.5A1 1 0 0 0 18 21V3.889c0-.481-.178-.954-.515-1.313A1.808 1.808 0 0 0 16.167 2H7.833Z"/>
                </svg>`;
    const svgOutline = `<svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m17 21-5-4-5 4V3.889a.92.92 0 0 1 .244-.629.808.808 0 0 1 .59-.26h8.333a.81.81 0 0 1 .589.26.92.92 0 0 1 .244.63V21Z"/>
                </svg>`;
    button.innerHTML = isBookmarked ? svgFilled : svgOutline;
    button.innerHTML += '<span class="sr-only">Bookmark meme</span>';
}
export function initMemesLoader(loadMoreUrl, rockBottomImageUrl, categoryId = '', isBookmarksPage = false) {
    let page = 1;
    let loading = false;
    let allMemesLoaded = false;

    function saveScrollState() {
        sessionStorage.setItem('scrollPosition', window.pageYOffset);
        sessionStorage.setItem('currentPage', page);
        sessionStorage.setItem('categoryId', categoryId);
    }

    setInterval(saveScrollState, 1000);

    function loadMoreMemes(targetPage = null) {
        return new Promise((resolve, reject) => {
            if (loading || allMemesLoaded) {
                resolve();
                return;
            }
            loading = true;
            const pageToLoad = targetPage || page + 1;
            document.getElementById('loading').classList.remove('hidden');

            const url = new URL(loadMoreUrl, window.location.origin);
            url.searchParams.append('page', pageToLoad);
            if (categoryId && !isBookmarksPage) {
                url.searchParams.append('category_id', categoryId);
            }

            fetch(url)
                .then(response => response.text())
                .then(data => {
                    if (data.trim().length) {
                        document.getElementById('memes-container').insertAdjacentHTML('beforeend', data);
                        window.initLazyLoading();
                        addVoteEventListeners();
                        initializeBookmarkButtons();
                        initFlowbite();
                        page = pageToLoad;
                        loading = false;
                        resolve();
                    } else {
                        allMemesLoaded = true;
                        const rockBottomHtml = `
                            <div id="rock-bottom" class="text-center mt-8">
                                <img src="${rockBottomImageUrl}" alt="Rock Bottom" class="mx-auto">
                                <p class="text-gray-500 mt-4">You've reached rock bottom. No more memes to load!</p>
                            </div>
                        `;
                        document.getElementById('memes-container').insertAdjacentHTML('beforeend', rockBottomHtml);
                        document.getElementById('loading').classList.add('hidden');
                        resolve();
                    }
                })
                .catch((error) => {
                    document.getElementById('loading').innerHTML = '<p class="text-red-500">Error loading memes.</p>';
                    reject(error);
                })
                .finally(() => {
                    loading = false;
                    document.getElementById('loading').classList.add('hidden');
                });
        });
    }

    function showContinueScrollingButton() {
        const savedPosition = sessionStorage.getItem('scrollPosition');
        const savedPage = parseInt(sessionStorage.getItem('currentPage'));
        const savedCategoryId = sessionStorage.getItem('categoryId');

        if (savedPosition && savedPage && savedPage > 2 && savedCategoryId === categoryId) {
            const button = document.createElement('button');
            button.innerText = 'Continue scrolling';
            button.className = 'fixed bottom-24 right-4 bg-yellow-400 text-white px-4 py-2 rounded';
            button.onclick = () => {
                restoreScrollState(parseInt(savedPosition), savedPage);
                button.remove();
            };
            document.body.appendChild(button);
        }
    }

    async function restoreScrollState(savedPosition, savedPage) {
        const loadingIndicator = document.createElement('div');
        loadingIndicator.className = 'fixed top-0 left-0 w-full h-1 bg-blue-500';
        document.body.appendChild(loadingIndicator);

        for (let i = page; i < savedPage; i++) {
            await loadMoreMemes(i);
            const progress = (i - page + 1) / (savedPage - page);
            loadingIndicator.style.width = `${progress * 100}%`;
        }

        loadingIndicator.remove();
        window.scrollTo(0, savedPosition);
    }

    window.addEventListener('load', showContinueScrollingButton);

    window.addEventListener('scroll', () => {
        if (!allMemesLoaded && window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
            console.log('Triggering loadMoreMemes');
            loadMoreMemes();
        }
    });
}

