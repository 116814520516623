document.addEventListener('DOMContentLoaded', function() {
    const players = document.querySelectorAll('.audio-player');

    players.forEach(player => {
        const audio = player.querySelector('.audio-element');
        const playPauseBtn = player.querySelector('.play-pause-btn');
        const muteBtn = player.querySelector('.mute-btn');
        const volumeSlider = player.querySelector('.volume-slider');
        const progressBar = player.querySelector('.progress-bar');
        const seekSlider = player.querySelector('.seek-slider');
        const currentTimeSpan = player.querySelector('.current-time');
        const durationSpan = player.querySelector('.duration');
        const skipBackward = player.querySelector('.skip-backward');
        const skipForward = player.querySelector('.skip-forward');
        const speedSelect = player.querySelector('.speed-select');
        const loopBtn = player.querySelector('.loop-btn');

        let isLooping = false;

        function formatTime(seconds) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = Math.floor(seconds % 60);
            return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
        }

        function togglePlayPause() {
            if (audio.paused) {
                audio.play().then(() => {
                    updateDuration();
                    updateProgress();
                }).catch(error => console.error("Error playing audio:", error));
                playPauseBtn.innerHTML = `
                    <svg class="w-16 h-16" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path>
                    </svg>
                `;
            } else {
                audio.pause();
                playPauseBtn.innerHTML = `
                    <svg class="w-16 h-16" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd"></path>
                    </svg>
                `;
            }
        }

        playPauseBtn.addEventListener('click', togglePlayPause);

        muteBtn.addEventListener('click', () => {
            audio.muted = !audio.muted;
            muteBtn.innerHTML = audio.muted ? `
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                </svg>
            ` : `
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z" clip-rule="evenodd"></path>
                </svg>
            `;
        });

        // Set default volume to 0.3
        audio.volume = 0.3;
        volumeSlider.value = 0.3;

        volumeSlider.addEventListener('input', () => {
            audio.volume = volumeSlider.value;
        });

        function updateProgress() {
            if (isFinite(audio.duration) && audio.duration > 0) {
                const progress = Math.round((audio.currentTime / audio.duration) * 100);
                progressBar.style.width = `${progress}%`;
                seekSlider.value = audio.currentTime;
                currentTimeSpan.textContent = formatTime(audio.currentTime);
            }
        }

        function updateDuration() {
            if (isFinite(audio.duration) && audio.duration > 0) {
                durationSpan.textContent = formatTime(audio.duration);
                seekSlider.max = audio.duration;
            } else {
                durationSpan.textContent = "00:00";
                seekSlider.max = 100;
            }
        }

        function tryUpdateDuration() {
            updateDuration();
            if (!isFinite(audio.duration) || audio.duration === 0) {
                setTimeout(tryUpdateDuration, 500);
            }
        }

        function initializeAudio() {
            updateDuration();
            updateProgress();
        }

        audio.addEventListener('loadedmetadata', initializeAudio);
        audio.addEventListener('canplay', initializeAudio);
        audio.addEventListener('canplaythrough', initializeAudio);
        audio.addEventListener('loadstart', tryUpdateDuration);
        audio.addEventListener('timeupdate', updateProgress);
        audio.addEventListener('play', updateProgress);

        audio.addEventListener('error', (e) => {
            console.error("Audio error:", e);
            currentTimeSpan.textContent = "Error";
            durationSpan.textContent = "Error";
        });

        if (!audio.src) {
            console.warn("Audio source not set");
        }

        function showSeekSlider() {
            seekSlider.style.opacity = '1';
        }

        function hideSeekSlider() {
            setTimeout(() => {
                seekSlider.style.opacity = '0';
            }, 3000); // Hide the slider after 3 seconds
        }

        player.addEventListener('touchstart', showSeekSlider);
        player.addEventListener('touchend', hideSeekSlider);

        seekSlider.addEventListener('mouseenter', showSeekSlider);
        seekSlider.addEventListener('mouseleave', hideSeekSlider);

        seekSlider.addEventListener('input', () => {
            showSeekSlider();
            const seekTime = audio.duration * (seekSlider.value / audio.duration);
            audio.currentTime = seekTime;
            progressBar.style.width = `${(seekTime / audio.duration) * 100}%`;
            updateProgress();
        });

        skipBackward.addEventListener('click', () => {
            audio.currentTime = Math.max(audio.currentTime - 5, 0);
        });

        skipForward.addEventListener('click', () => {
            audio.currentTime = Math.min(audio.currentTime + 5, audio.duration);
        });

        speedSelect.addEventListener('change', () => {
            audio.playbackRate = parseFloat(speedSelect.value);
        });

        loopBtn.addEventListener('click', () => {
            isLooping = !isLooping;
            audio.loop = isLooping;
            loopBtn.classList.toggle('text-yellow-300', isLooping);
            loopBtn.classList.toggle('dark:text-yellow-400', isLooping);
            loopBtn.classList.toggle('text-gray-500', isLooping);
            loopBtn.classList.toggle('dark:text-gray-400', isLooping);
        });

        // Intersection Observer to pause when out of view
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (!entry.isIntersecting && !audio.paused) {
                    audio.pause();
                    playPauseBtn.innerHTML = `
                        <svg class="w-16 h-16" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd"></path>
                        </svg>
                    `;
                }
            });
        }, { threshold: 0.5 });

        observer.observe(player);
    });
});


document.addEventListener('DOMContentLoaded', () => {
    const allPlayers = document.querySelectorAll('.audio-player, media-player');

    allPlayers.forEach(player => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (!entry.isIntersecting) {
                        // Überprüfen Sie, ob es sich um einen Audio- oder Video-Player handelt
                        if (player.classList.contains('audio-player')) {
                            const audioElement = player.querySelector('audio');
                            if (audioElement && !audioElement.paused) {
                                audioElement.pause();
                            }
                        } else if (player.tagName.toLowerCase() === 'media-player') {
                            if (!player.paused) {
                                player.pause();
                            }
                        }
                    } else {
                        if (player.tagName.toLowerCase() === 'media-player') {
                            // Überprüfen Sie, ob der Player bereit ist, bevor Sie versuchen abzuspielen
                            if (player.readyState >= 2) { // HAVE_CURRENT_DATA or higher
                                player.play().catch(error => console.error("Error playing video:", error));
                            } else {
                                // Warten Sie, bis der Player bereit ist
                                player.addEventListener('canplay', () => {
                                    player.play().catch(error => console.error("Error playing video:", error));
                                }, { once: true });
                            }
                        }
                    }
                });
            },
            { threshold: 0.5 }
        );

        observer.observe(player);
    });

    const players = document.querySelectorAll('media-player');

    players.forEach(player => {
        // Setzen Sie die initiale Lautstärke auf 0.3, aber behalten Sie den Player stummgeschaltet
        player.volume = 0.3;
        player.muted = true;

        // Fügen Sie einen Event-Listener für das 'volumechange'-Event hinzu
        player.addEventListener('volumechange', () => {
            // Wenn die Stummschaltung aufgehoben wird, stellen Sie sicher, dass die Lautstärke 0.3 ist
            if (!player.muted && player.volume === 0) {
                player.volume = 0.3;
            }
        });
    });

});

