import './bootstrap';
import 'flowbite';
import { Dismiss } from 'flowbite';
import { showToast } from './toast';
import {initializeBookmarkButtons, initMemesLoader} from './memes';
import { addVoteEventListeners } from './vote';
import { Popover } from 'flowbite';
import './players';
import './report';
import './share';

window.showToast = showToast;
window.initMemesLoader = initMemesLoader;
window.initializeBookmarkButtons = initializeBookmarkButtons;
function initLazyLoading() {
    const lazyImages = document.querySelectorAll(".lazy:not(.lazy-loaded)");
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const img = entry.target;
                img.src = img.getAttribute("data-src");
                img.classList.remove("lazy");
                img.classList.add("lazy-loaded");
                observer.unobserve(img);
            }
        });
    });
    lazyImages.forEach(img => {
        observer.observe(img);
    });
}
export function initializePopovers() {
    const popoverTriggers = document.querySelectorAll('[data-popover-target]');
    popoverTriggers.forEach(trigger => {
        const targetId = trigger.getAttribute('data-popover-target');
        const target = document.getElementById(targetId);
        if (target && !trigger.hasAttribute('data-popover-initialized')) {
            const popover = new Popover(target, trigger);
            trigger.setAttribute('data-popover-initialized', 'true');
        }
    });
}

window.initLazyLoading = initLazyLoading;
document.addEventListener('DOMContentLoaded', () => {
    initLazyLoading();
    addVoteEventListeners();
    initializePopovers();
    initializeBookmarkButtons();
});


